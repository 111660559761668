import { useCallback, useState } from "react";
import { JoinRequest } from "../../../models/meeting";

export interface UseJoinRequests {
  joinRequests: JoinRequest[];
  removeJoinRequest: (requestId: string) => void;
  addJoinRequest: (joinRequest: JoinRequest) => void;
  markJoinRequestAsTimedOut: (requestId: string) => void;
}

const useJoinRequests = (): UseJoinRequests => {
  const [joinRequests, setJoinRequests] = useState<JoinRequest[]>([]);

  const removeJoinRequest = useCallback(
    (requestId: string) => {
      setJoinRequests((prevState) =>
        prevState.filter((m) => m.id !== requestId)
      );
    },
    [setJoinRequests]
  );

  const addJoinRequest = useCallback(
    (joinRequest: JoinRequest) => {
      setJoinRequests((prevState) => prevState.concat(joinRequest));
    },
    [setJoinRequests]
  );

  const markJoinRequestAsTimedOut = useCallback(
    (requestId: string) => {
      setJoinRequests((prevState) =>
        prevState.map((m) => ({
          ...m,
          isTimedOut: requestId === m.id ? true : m.isTimedOut
        }))
      );
    },
    [setJoinRequests]
  );

  return {
    joinRequests,
    removeJoinRequest,
    addJoinRequest,
    markJoinRequestAsTimedOut
  };
};

export default useJoinRequests;
