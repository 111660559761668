import { Flex, Spin } from "antd";

import styles from "./Loader.module.scss";

/**
 * Right now it's only full screen, but please adjust if you need it to be more flexible.
 */
export function Loader() {
  return (
    <Flex align="center" justify="center" className={styles.loadingWrapper}>
      <Spin size="large" />
    </Flex>
  );
}
