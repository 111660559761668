import moment from "moment";
import React, { useState } from "react";
import { MeetingContextProvider } from "../../contexts/MeetingContext";
import { useSelectedParticipant } from "../../hooks";
import {
  useLocalTracks,
  useRoom,
  useShareScreen
} from "../../hooks/appContext";
import useEventSidebar from "../../hooks/appContext/useEventSidebar/useEventSidebar";
import useJoinRequests from "../../hooks/appContext/useJoinRequests/UseJoinRequest";
import useParticipantSidebar from "../../hooks/appContext/useParticipantSidebar/useParticipantSidebar";
import { Timezone } from "../../models/meeting";

const MeetingProvider = ({ children }: { children: any }) => {
  const {
    localAudioTrack,
    localVideoTrack,
    enableAudioTrack,
    disableVideoTrack,
    disableAudioTrack,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isCamRequesting,
    isMicRequesting
  } = useLocalTracks();

  const {
    joinRequests,
    addJoinRequest,
    removeJoinRequest,
    markJoinRequestAsTimedOut
  } = useJoinRequests();

  const {
    connect,
    isConnecting,
    meetingError,
    meeting,
    userEmail,
    twilioToken,
    room,
    setMeetingLoading,
    setMeetingError,
    approvalType,
    isMeetingGuest,
    isOrganizer,
    isRecording,
    setRecording,
    isRecordingCounter
  } = useRoom(
    addJoinRequest,
    removeJoinRequest,
    localAudioTrack,
    localVideoTrack
  );
  const { isParticipantSidebarOpen, toggleParticipantSidebar } =
    useParticipantSidebar(room);
  const { isScreenSharing, toggleScreenShare } = useShareScreen(room);
  const { selectedParticipant, chooseSelectedParticipant } =
    useSelectedParticipant(room);

  const { isEventSidebarOpen, toggleEventSidebar } = useEventSidebar(room);
  const [sinkId, setSinkId] = useState<string>();
  const [timezones, setTimezones] = useState<Timezone[]>([]);

  const getTimezoneDisplayText = (): string =>
    (timezones.find((tz) => tz.offset === moment().utcOffset()) || timezones[0])
      ?.displayText;

  return (
    <MeetingContextProvider
      value={{
        isCamRequesting,
        isMicRequesting,
        localAudioTrack,
        localVideoTrack,
        disableVideoTrack,
        enableAudioTrack,
        disableAudioTrack,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isConnecting,
        meetingError,
        meeting,
        userEmail,
        twilioToken,
        room,
        setMeetingLoading,
        setMeetingError,
        isParticipantSidebarOpen,
        toggleParticipantSidebar,
        isScreenSharing,
        toggleScreenShare,
        sinkId,
        setSinkId,
        selectedParticipant,
        chooseSelectedParticipant,
        isEventSidebarOpen,
        toggleEventSidebar,
        timezones,
        setTimezones,
        getTimezoneDisplayText,
        approvalType,
        joinRequests,
        removeJoinRequest,
        addJoinRequest,
        markJoinRequestAsTimedOut,
        isMeetingGuest,
        isOrganizer,
        isRecording,
        setRecording,
        isRecordingCounter
      }}
    >
      {children}
    </MeetingContextProvider>
  );
};

export default MeetingProvider;
