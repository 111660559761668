import { Stream, VideoClient } from "@zoom/videosdk";

import { ZoomEvents } from "../../constants/zoomEvents";
import { ScreenShareUI } from "./ScreenShareUI";

export const STARTED_SHARING_EVENT = "startedSharing";
export const STOPPED_SHARING_EVENT = "stoppedSharing";

interface ActiveShareChange {
  state: "Active" | "Inactive";
  userId: number;
}

export enum SharingActor {
  Me = "me",
  Peer = "peer"
}

export class MeetingScreenShare extends EventTarget {
  private client: typeof VideoClient;
  private stream: typeof Stream | undefined;

  private screenShareUI: ScreenShareUI;

  private _isSelfSharing = false;
  get isSelfSharing() {
    return this._isSelfSharing;
  }

  private _isPeerSharing = false;
  get isPeerSharing() {
    return this._isPeerSharing;
  }

  get isSelfOrPeerSharing() {
    return this._isSelfSharing || this._isPeerSharing;
  }

  private onStopShare = () => this.stop();

  private onShareChange = (payload: ActiveShareChange) =>
    this.onActiveShareChange(payload);

  constructor(client: typeof VideoClient) {
    super();

    this.client = client;
    this.screenShareUI = new ScreenShareUI();
  }

  join(stream: typeof Stream) {
    this.screenShareUI.loadDocument();

    this.stream = stream;

    this.client.on(ZoomEvents.PassivelyStopShare, this.onStopShare);
    this.client.on(ZoomEvents.ActiveShareChange, this.onShareChange);

    this.showCurrentlySharedScreen();
  }

  leave() {
    this.client.off(ZoomEvents.PassivelyStopShare, this.onStopShare);
    this.client.off(ZoomEvents.ActiveShareChange, this.onShareChange);
  }

  private async showCurrentlySharedScreen() {
    const participants = this.client.getAllUser();
    const peerThatShares = participants.find(
      (participant) => participant.sharerOn
    );
    if (peerThatShares) {
      await this.receiveSharingFromPeer(peerThatShares.userId);
    }
  }

  async start() {
    if (!this.stream) throw new Error("Stream not initialized!");

    const screenShareElement = this.stream.isStartShareScreenWithVideoElement()
      ? this.screenShareUI.shareVideoElement
      : this.screenShareUI.shareCanvasElement;
    if (!screenShareElement) {
      throw new Error("Screen share HTML element not found!");
    }

    await this.stream.startShareScreen(screenShareElement);
    this._isSelfSharing = true;
    this.screenShareUI.setUpSharingView(screenShareElement);
    this.dispatchEvent(
      new CustomEvent(STARTED_SHARING_EVENT, {
        detail: { who: SharingActor.Me }
      })
    );
  }

  async stop() {
    if (!this.stream) throw new Error("Stream not initialized!");

    this.screenShareUI.tearDownSharingView();
    this._isSelfSharing = false; // Has to be set before dispatching the event!
    this.dispatchEvent(
      new CustomEvent(STOPPED_SHARING_EVENT, {
        detail: { who: SharingActor.Me }
      })
    );
    await this.stream.stopShareScreen();
  }

  private async onActiveShareChange({ state, userId }: ActiveShareChange) {
    if (!this.stream) throw new Error("Stream not initialized!");

    if (state === "Active") {
      this.receiveSharingFromPeer(userId);
    } else if (state === "Inactive") {
      this.screenShareUI.tearDownSharingView();
      this._isPeerSharing = false; // Has to be set before dispatching the event!
      this.dispatchEvent(
        new CustomEvent(STOPPED_SHARING_EVENT, {
          detail: { who: SharingActor.Peer }
        })
      );
      await this.stream.stopShareView();
    }
  }

  private async receiveSharingFromPeer(userId: number) {
    if (this._isSelfSharing) {
      await this.stop();
    }

    await this.stream!.startShareView(
      this.screenShareUI.peerShareCanvasElement!,
      userId
    );
    this._isPeerSharing = true;
    this.screenShareUI.setUpSharingView();
    this.dispatchEvent(
      new CustomEvent(STARTED_SHARING_EVENT, {
        detail: { who: SharingActor.Peer }
      })
    );
  }
}
