import { Room } from "twilio-video";

import { GUEST_APPROVAL_STATE } from "../../../constants/meeting";
import { ErrorResponse } from "../../../models/api";
import { CIQParticipant, Meeting } from "../../../models/meeting";
import { MeetingState } from "./UseRoom";

export const GET_MEETING = "GET_MEETING";
export const GET_MEETING_SUCCESS = "GET_MEETING_SUCCESS";
export const GET_MEETING_FAILURE = "GET_MEETING_FAILURE";
export const DISCONNECT_MEETING = "DISCONNECT_MEETING";
export const UPDATE_GUEST_APPROVAL_STATE = "UPDATE_GUEST_APPROVAL_STATE";
export const ADD_NEW_PARTICIPANT = "ADD_NEW_PARTICIPANT";
export const ADD_PARTICIPANTS = "ADD_PARTICIPANTS";
export const SET_RECORDING = "SET_RECORDING";

interface GetMeetingActionType {
  type: typeof GET_MEETING;
}
interface GetMeetingSuccessActionType {
  type: typeof GET_MEETING_SUCCESS;
  payload: {
    meeting: Meeting;
    room: Room;
    email: string;
    twilioToken: string;
    isMeetingGuest?: boolean;
    isOrganizer: boolean;
  };
}

interface SetRecordingActionType {
  type: typeof SET_RECORDING;
  payload: {
    isRecording: boolean;
    isRecordingCounter: number;
  };
}

interface DisconnectMeetingActionType {
  type: typeof DISCONNECT_MEETING;
  payload: {
    room: Room;
    isMeetingGuest?: boolean;
  };
}

interface AddNewParticipantActionType {
  type: typeof ADD_NEW_PARTICIPANT;
  payload: {
    participant: CIQParticipant;
  };
}

interface AddParticipantsActionType {
  type: typeof ADD_PARTICIPANTS;
  payload: {
    participants: CIQParticipant[];
  };
}

interface GetMeetingFailureActionType {
  type: typeof GET_MEETING_FAILURE;
  payload: {
    meetingError?: ErrorResponse;
  };
}

interface UpdateApprovalType {
  type: typeof UPDATE_GUEST_APPROVAL_STATE;
  payload: {
    approvalType?: GUEST_APPROVAL_STATE;
    isConnecting: boolean;
  };
}

type MeetingReducerAction =
  | GetMeetingActionType
  | GetMeetingSuccessActionType
  | GetMeetingFailureActionType
  | DisconnectMeetingActionType
  | UpdateApprovalType
  | AddNewParticipantActionType
  | SetRecordingActionType
  | AddParticipantsActionType;

export function meetingReducer(
  state: MeetingState,
  action: MeetingReducerAction
): MeetingState {
  switch (action.type) {
    case GET_MEETING:
      return { ...state, isConnecting: true, meetingError: undefined };
    case GET_MEETING_SUCCESS:
      return {
        ...state,
        isConnecting: false,
        meeting: action.payload.meeting,
        room: action.payload.room,
        userEmail: action.payload.email,
        twilioToken: action.payload.twilioToken,
        isMeetingGuest: action.payload.isMeetingGuest,
        isOrganizer: action.payload.isOrganizer,
        isRecording: action.payload.meeting.record,
        isRecordingCounter: 0
      };
    case GET_MEETING_FAILURE:
      return {
        ...state,
        isConnecting: false,
        meetingError: action.payload.meetingError,
        approvalType: undefined
      };

    case SET_RECORDING:
      return {
        ...state,
        isRecording: !action.payload.isRecording,
        isRecordingCounter: action.payload.isRecording
          ? action.payload.isRecordingCounter + 1
          : action.payload.isRecordingCounter
      };

    case DISCONNECT_MEETING:
      return {
        ...state,
        room: action.payload.room,
        meeting: undefined,
        approvalType: undefined,
        isMeetingGuest: undefined,
        twilioToken: undefined,
        userEmail: undefined
      };
    case UPDATE_GUEST_APPROVAL_STATE:
      return {
        ...state,
        approvalType: action.payload.approvalType,
        isConnecting: action.payload.isConnecting
      };
    case ADD_NEW_PARTICIPANT: {
      const { meeting } = state;

      if (meeting) {
        const { participants: ExistParticipant } = meeting;
        const { participant } = action.payload;
        const { email } = participant;

        let updatedParticipants = [...ExistParticipant];
        const existingIndex = updatedParticipants.findIndex(
          (eP) => eP.email === email
        );

        if (existingIndex > -1) {
          updatedParticipants[existingIndex] = { ...participant };
        } else {
          updatedParticipants = updatedParticipants.concat(participant);
        }

        return {
          ...state,
          meeting: {
            ...meeting,
            participants: updatedParticipants
          }
        };
      }

      return {
        ...state
      };
    }
    case ADD_PARTICIPANTS: {
      const { meeting } = state;
      const { participants } = action.payload;

      if (meeting) {
        const { participants: ExistParticipant } = meeting;
        let updatedParticipants = [...ExistParticipant];

        participants.forEach((newP) => {
          const existingIndex = updatedParticipants.findIndex(
            ({ email }) => email === newP.email
          );

          if (existingIndex > -1) {
            updatedParticipants[existingIndex] = { ...newP };
          } else {
            updatedParticipants = updatedParticipants.concat(newP);
          }
        });

        return {
          ...state,
          meeting: {
            ...meeting,
            participants: updatedParticipants
          }
        };
      }

      return {
        ...state
      };
    }
    default:
      return state;
  }
}
