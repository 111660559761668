import React, { Component, ErrorInfo } from "react";
import {
  browserName,
  deviceType,
  engineName,
  engineVersion,
  fullBrowserVersion,
  mobileModel,
  mobileVendor,
  osName,
  osVersion
} from "react-device-detect";
import { WithTranslation, withTranslation } from "react-i18next";

import { Subscription } from "rxjs";
import {
  MeetingContext,
  MeetingContextState
} from "../../../contexts/MeetingContext";
import { ConnectCrashAnalytics, ERROR_STATES } from "../../../models/meeting";
import {
  AnalyticsService,
  DiagnosticService,
  LegacyMeetingService
} from "../../../services";

interface MeetingErrorBoundaryProps extends WithTranslation {
  children: React.ReactNode;
}
interface MeetingErrorBoundaryState {
  hasError: boolean;
}

class MeetingErrorBoundary extends Component<
  MeetingErrorBoundaryProps,
  MeetingErrorBoundaryState
> {
  static getDerivedStateFromError(): MeetingErrorBoundaryState {
    return { hasError: true };
  }

  constructor(props: MeetingErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch({ message }: Error, errorInfo: ErrorInfo): void {
    const meetingContextState = this.context as MeetingContextState;
    const { userEmail } = meetingContextState;
    const meetingId = LegacyMeetingService.getMeetingIdFromURL();

    if (process.env.NODE_ENV === "production") {
      const errorData: ConnectCrashAnalytics = {
        errorMessage: message,
        errorType: ERROR_STATES.APP_CRASH_ERROR,
        browserName,
        deviceType,
        engineName,
        engineVersion,
        fullBrowserVersion,
        osName,
        osVersion,
        meetingId,
        mobileModel,
        mobileVendor
      };

      AnalyticsService.meetingUnhandledException(errorData);

      this.appCrashSubscription = DiagnosticService.sendAppCrashData(
        userEmail!,
        errorData
      ).subscribe();
    } else {
      console.log(errorInfo);
    }
  }

  componentWillUnmount(): void {
    this.appCrashSubscription?.unsubscribe();
  }

  private appCrashSubscription?: Subscription;

  render(): JSX.Element {
    const { hasError } = this.state;
    const { t, children } = this.props;

    return (
      <>
        {hasError ? (
          <div className="error-boundary-container">
            <div className="error-boundary-message">
              <h1>{t("somethingWentWrong")}</h1>
              <p>{t("crashedMessage")}</p>
            </div>
          </div>
        ) : (
          children
        )}
      </>
    );
  }
}

MeetingErrorBoundary.contextType = MeetingContext;

export default withTranslation("meetingErrorBoundary")(MeetingErrorBoundary);
