import { useCallback, useEffect, useState } from "react";
import { Room } from "twilio-video";
import { ROOM_STATE_TYPE } from "../../../constants/events";

export interface UseEventSidebar {
  isEventSidebarOpen: boolean;
  toggleEventSidebar: () => void;
}

const useEventSidebar = (room: Room): UseEventSidebar => {
  const [isEventSidebarOpen, setIsEventSidebarOpen] = useState(false);

  const toggleEventSidebar = useCallback(() => {
    setIsEventSidebarOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    room.once(ROOM_STATE_TYPE.DISCONNECTED, () => {
      setIsEventSidebarOpen(false);
    });
  }, [room]);

  return {
    isEventSidebarOpen,
    toggleEventSidebar
  };
};

export default useEventSidebar;
