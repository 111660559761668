import ZoomVideo, {
  ActiveSpeaker,
  Participant,
  Stream,
  VideoClient,
  VideoPlayer,
  VideoQuality,
  LocalVideoTrack
} from "@zoom/videosdk";

import { NotificationService } from "..";
import { ZoomEvents } from "../../constants/zoomEvents";
import { debug } from "../../utils/logging";
import { getParticipantsWithoutSelf } from "../../utils/zoom";
import {
  MeetingScreenShare,
  STARTED_SHARING_EVENT,
  STOPPED_SHARING_EVENT
} from "./MeetingScreenShare";
import { VideoUI } from "./VideoUI";

const VideoState = {
  Start: "Start",
  Stop: "Stop"
} as const;

export const MAX_VIDEO_TILES = 6;

interface PeerVideoStateChange {
  action: keyof typeof VideoState;
  userId: number;
}

export class MeetingVideo {
  private client: typeof VideoClient;
  private stream: typeof Stream | undefined;
  private screenShare: MeetingScreenShare;
  private videoUI: VideoUI;
  private videoPreviewElement: HTMLVideoElement | undefined;
  private localVideoTrack: LocalVideoTrack | undefined;
  cameraId: string | undefined;
  private currentUser: Participant | undefined;
  private userVideos: Record<number, VideoPlayer | HTMLDivElement> = {};

  private onActiveSpeaker = (activeSpeakers: ActiveSpeaker[]) =>
    this.onActiveSpeakerChange(activeSpeakers);
  private onPeerVideoChange = (payload: PeerVideoStateChange) =>
    this.onVideoStateChange(payload);
  private onUserUpdated = (participants: Participant[]) =>
    this.handleParticipantUiChange(participants);

  get isSelfSharingScreen() {
    return this.screenShare.isSelfSharing;
  }
  get isPeerSharingScreen() {
    return this.screenShare.isPeerSharing;
  }
  addStartShareListener: (listener: (e: Event) => void) => void;
  removeStartShareListener: (listener: (e: Event) => void) => void;
  addStopShareListener: (listener: (e: Event) => void) => void;
  removeStopShareListener: (listener: (e: Event) => void) => void;

  constructor(client: typeof VideoClient) {
    this.client = client;

    this.screenShare = new MeetingScreenShare(this.client);
    this.screenShare.addEventListener(STARTED_SHARING_EVENT, async () => {
      await this.removePeerVideos();
      this.attachVideosWithoutLimit();
    });
    this.screenShare.addEventListener(STOPPED_SHARING_EVENT, async () => {
      await this.removePeerVideos();
      await this.showPaginatedVideos(0);
      this.updateParticipantsGridStyle();
    });

    this.videoUI = new VideoUI();

    this.addStartShareListener = (listener) =>
      this.screenShare.addEventListener(STARTED_SHARING_EVENT, listener);
    this.removeStartShareListener = (listener) =>
      this.screenShare.removeEventListener(STARTED_SHARING_EVENT, listener);
    this.addStopShareListener = (listener) =>
      this.screenShare.addEventListener(STOPPED_SHARING_EVENT, listener);
    this.removeStopShareListener = (listener) =>
      this.screenShare.removeEventListener(STOPPED_SHARING_EVENT, listener);
  }

  async join(stream: typeof Stream) {
    this.stream = stream;
    this.screenShare.join(stream);
    this.currentUser = this.client.getCurrentUserInfo();

    this.videoUI.loadDocument();
    await this.startSelfVideo();
    await this.showParticipantsVideos();

    this.client.on(ZoomEvents.ActiveSpeaker, this.onActiveSpeaker);
    this.client.on(ZoomEvents.PeerVideoStateChange, this.onPeerVideoChange);
    this.client.on(ZoomEvents.UserUpdated, this.onUserUpdated);
  }

  async leave() {
    this.client.off(ZoomEvents.ActiveSpeaker, this.onActiveSpeaker);
    this.client.off(ZoomEvents.PeerVideoStateChange, this.onPeerVideoChange);
    this.client.off(ZoomEvents.UserUpdated, this.onUserUpdated);

    this.screenShare.leave();
    await this.screenShare.stop();

    await this.removePeerVideos();
    await this.stopVideoPreview();
    try {
      await this.stream?.stopVideo();
      await this.stream?.detachVideo(this.currentUser!.userId);
    } catch (error) {
      // No need to act on this error since we're leaving anyways
      console.error({ error });
    }
  }

  private removePeerVideos(): Promise<void[]> {
    return Promise.all(
      Object.entries(this.userVideos).map(async ([userId, element]) => {
        const numericId = Number(userId);

        if (numericId === this.currentUser?.userId) return;

        await this.stream?.detachVideo(numericId);
        element.remove();
        delete this.userVideos[numericId];
      })
    );
  }

  // Video preview is used in settings - it's not the same as self video in the meeting!

  async startVideoPreview(deviceId: string, target: "lobby" | "modal") {
    this.videoPreviewElement = VideoUI.getVideoPreviewElement(target);
    if (!this.videoPreviewElement) {
      debug("Video preview target element not found!");
      return;
    }

    this.localVideoTrack = ZoomVideo.createLocalVideoTrack(deviceId);
    try {
      await this.localVideoTrack.start(this.videoPreviewElement);
    } catch (error) {
      if (error.name === "NotAllowedError") {
        debug("Camera access denied!");
      } else {
        throw error;
      }
    }
  }

  async stopVideoPreview() {
    if (!this.localVideoTrack) return;
    try {
      await this.localVideoTrack.stop();
    } catch (error) {
      if (error.message === "VideoNotStartedError") {
        debug("Can't stop self video preview - video not started!");
      } else {
        throw error;
      }
    }
    this.localVideoTrack = undefined;
  }

  // Self video - visible in the meeting

  async startSelfVideo() {
    if (!this.stream) throw new Error("Stream not initialized!");
    if (!this.currentUser) throw new Error("Current user not set!");

    try {
      await this.stream.startVideo({
        cameraId: this.cameraId,
        hd: true,
        mirrored: true
      });
    } catch (error) {
      if (error.type === "VIDEO_USER_FORBIDDEN_CAPTURE") {
        debug("User denied camera access, adding placeholder tile");
        this.addPlaceholderVideoTile(this.currentUser);
        return;
      } else {
        throw error;
      }
    }

    try {
      const { userId } = this.currentUser;

      const previousVideoPlayer = this.userVideos[userId];
      const userVideo = (await this.stream.attachVideo(
        userId,
        // Web Video SDK currently only supports subscribing to one 720p video stream
        VideoQuality.Video_720P,
        previousVideoPlayer as VideoPlayer
      )) as VideoPlayer;

      if (!previousVideoPlayer) {
        this.userVideos[userId] = userVideo;
        this.videoUI.prependUserVideo(userVideo, this.currentUser);
      } else {
        VideoUI.toggleInitials(userVideo.shadowRoot, false);
      }
    } catch (error) {
      console.error({ error });
      NotificationService.showError(JSON.stringify(error));
    }
  }

  async stopSelfVideo() {
    if (!this.stream) throw new Error("Stream not initialized!");
    if (!this.currentUser) throw new Error("Current user not set!");

    const { userId } = this.currentUser;

    try {
      await this.stream.stopVideo();
    } catch (error) {
      if (error.reason === "camera is closed") {
        debug("Can't stop self video - camera is closed!");
        return;
      } else {
        throw error;
      }
    }

    const detached = await this.stream.detachVideo(userId);
    const elements = Array.isArray(detached) ? detached : [detached];
    for (const element of elements) {
      if (!element) continue;
      VideoUI.toggleInitials(element.shadowRoot, true);
    }
  }

  // Videos of peers

  private async showParticipantsVideos() {
    if (!this.stream) throw new Error("Stream not initialized!");
    if (!this.currentUser) throw new Error("Current user not set!");

    const participants = this.client.getAllUser();

    for (const participant of participants) {
      if (participant.userId === this.currentUser.userId) continue;
      if (this.cannotAddMoreTiles()) break;

      if (participant.bVideoOn) {
        await this.addVideoTile(participant);
      } else {
        this.addPlaceholderVideoTile(participant);
      }
    }

    this.updateParticipantsGridStyle();
  }

  // Returns true if new page was rendered, otherwise false
  async showPaginatedVideos(page: number) {
    const participants = [
      this.currentUser as Participant,
      ...getParticipantsWithoutSelf(this.client)
    ];
    const sliceStart = page * MAX_VIDEO_TILES;
    const sliceEnd = sliceStart + MAX_VIDEO_TILES;
    const fromRequestedPage = participants.slice(sliceStart, sliceEnd);

    if (fromRequestedPage.length === 0) {
      console.warn(`There are no videos on page page ${page}!`);
      return false;
    }

    for (const userId in this.userVideos) {
      if (Number(userId) === this.currentUser?.userId) {
        await this.stopSelfVideo();
      }
      await this.removeParticipantVideo(Number(userId));
    }

    for (const participant of fromRequestedPage) {
      if (participant.userId === this.currentUser?.userId) {
        await this.startSelfVideo();
        continue;
      }

      if (participant.bVideoOn) {
        await this.addVideoTile(participant);
      } else {
        this.addPlaceholderVideoTile(participant);
      }
    }

    this.updateParticipantsGridStyle();

    return true;
  }

  private async onVideoStateChange({ action, userId }: PeerVideoStateChange) {
    if (!this.stream) throw new Error("Stream not initialized!");

    if (action === VideoState.Start) {
      let previousVideoPlayer: HTMLElement | undefined =
        this.userVideos[userId];
      if (!previousVideoPlayer && this.cannotAddMoreTiles()) {
        return;
      }

      // We need `.lowercase()` to ensure predictable values in every browser
      if (previousVideoPlayer?.tagName.toLowerCase() === "div") {
        this.removePlaceholderVideoTile(userId);
        previousVideoPlayer = undefined;
      }

      const videoPlayerElement = await this.addVideoTile(
        this.client.getUser(userId)!,
        previousVideoPlayer
      );

      VideoUI.toggleInitials(videoPlayerElement.shadowRoot, false);
    }

    if (action === VideoState.Stop) {
      const detached = await this.stream.detachVideo(userId);
      const elements = Array.isArray(detached) ? detached : [detached];
      for (const element of elements) {
        if (typeof element === "undefined") continue;
        VideoUI.toggleInitials(element.shadowRoot, true);
      }
    }

    this.updateParticipantsGridStyle();
  }

  addPlaceholderVideoTile(user: Participant, withInitials = true) {
    if (this.cannotAddMoreTiles()) return;
    // Placeholder already in the document
    if (this.userVideos[user.userId]?.tagName.toLowerCase() === "div") return;

    const placeholderVideo = this.videoUI.addPlaceholderVideoTile(
      user,
      withInitials
    );
    this.userVideos[user.userId] = placeholderVideo;
  }

  async addVideoTile(
    participant: Participant,
    previousVideoPlayer?: VideoPlayer | HTMLElement
  ) {
    const videoPlayerElement = (await this.stream!.attachVideo(
      participant.userId,
      VideoQuality.Video_360P,
      previousVideoPlayer as VideoPlayer | undefined
    )) as VideoPlayer;

    if (!previousVideoPlayer) {
      this.userVideos[participant.userId] = videoPlayerElement;
      this.videoUI.addUserVideo(videoPlayerElement, participant);
    }

    return videoPlayerElement;
  }

  addPlaceholderVideoTiles(addedParticipants: Participant[]) {
    if (this.cannotAddMoreTiles()) return;

    for (const participant of addedParticipants) {
      if (participant.isPhoneUser) {
        this.addPlaceholderVideoTile(
          { ...participant, displayName: "Dial-in" },
          false
        );
      } else {
        this.addPlaceholderVideoTile(participant);
      }
    }
    this.updateParticipantsGridStyle();
  }

  private removePlaceholderVideoTile(userId: number) {
    this.userVideos[userId]?.remove();
    delete this.userVideos[userId];
  }

  async removeParticipantVideo(userId: number) {
    const userVideo = this.userVideos[userId];
    if (!userVideo) return;
    await this.stream?.detachVideo(userId);
    userVideo.remove();
    delete this.userVideos[userId];
    this.updateParticipantsGridStyle();
  }

  private handleParticipantUiChange(participants: Participant[]) {
    for (const participant of participants) {
      if ("muted" in participant === false) continue;

      const videoForChangedUser = this.userVideos[participant.userId];
      if (!videoForChangedUser) continue;

      VideoUI.updateMuteIconInsideNameElement(
        videoForChangedUser.shadowRoot ?? videoForChangedUser,
        participant.userId,
        !!participant.muted
      );
    }
  }

  private onActiveSpeakerChange(activeSpeakers: ActiveSpeaker[]) {
    for (const speaker of activeSpeakers) {
      const userVideo = this.userVideos[speaker.userId];
      if (userVideo) this.videoUI.setActiveSpeaker(userVideo, speaker.userId);
    }
  }

  private updateParticipantsGridStyle() {
    if (this.screenShare.isSelfOrPeerSharing) return;
    const participants = Object.keys(this.userVideos).length;
    this.videoUI.setVideoGridStyle(participants);
  }

  private async attachVideosWithoutLimit() {
    for (const participant of this.client.getAllUser()) {
      if (participant.userId === this.currentUser!.userId) continue;

      if (participant.bVideoOn) {
        await this.addVideoTile(participant);
      } else {
        this.addPlaceholderVideoTile(participant);
      }
    }
  }

  cannotAddMoreTiles() {
    return Object.keys(this.userVideos).length >= MAX_VIDEO_TILES;
  }

  // Screen sharing

  async shareMyScreen() {
    await this.screenShare.start();
  }

  async stopSharingScreen() {
    await this.screenShare.stop();
  }

  // Device management

  async getCameraList() {
    const devices = await ZoomVideo.getDevices();
    const videoDevices = devices.filter(
      // A device with an empty ID is just a placeholder, not real one
      (device) => device.kind === "videoinput" && device.deviceId.length > 0
    );
    return videoDevices;
  }

  async switchCameraInput(deviceId: string) {
    this.cameraId = deviceId;
    await this.stream?.switchCamera(deviceId);
  }
}
