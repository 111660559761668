import { useCallback, useEffect, useState } from "react";
import { Room } from "twilio-video";
import { ROOM_STATE_TYPE } from "../../../constants/events";

export interface UseParticipantSidebar {
  isParticipantSidebarOpen: boolean;
  toggleParticipantSidebar: () => void;
}

const useParticipantSidebar = (room: Room): UseParticipantSidebar => {
  const [isParticipantSidebarOpen, setIsParticipantSidebarOpen] =
    useState(false);

  const toggleParticipantSidebar = useCallback(() => {
    setIsParticipantSidebarOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    room.once(ROOM_STATE_TYPE.DISCONNECTED, () => {
      setIsParticipantSidebarOpen(false);
    });
  }, [room]);

  return {
    isParticipantSidebarOpen,
    toggleParticipantSidebar
  };
};

export default useParticipantSidebar;
