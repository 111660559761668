import React from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface CompatibilityModalProps {
  isOpen: boolean;
  toggledModal: () => void;
}

const CompatibilityModal = ({
  isOpen,
  toggledModal
}: CompatibilityModalProps): JSX.Element => {
  const { t } = useTranslation("permissionModal");

  const modalPortal = document.getElementById("modal-portal");

  return createPortal(
    <Modal
      isOpen={isOpen}
      toggle={toggledModal}
      className="compatibility-modal"
    >
      <ModalHeader>Browser Compatibility</ModalHeader>
      <ModalBody>
        <p>
          The following table indicates the browsers supported by Arbolus
          Connect.
        </p>
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Chrome</th>
              <th>Firefox</th>
              <th>Safari</th>
              <th>
                Edge <span>(Chromium)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="right">Android</td>
              <td align="left">
                <i className="ciq-icon ciq-check" />
              </td>
              <td align="left">
                <i className="ciq-icon ciq-check" />
              </td>
              <td align="left">-</td>
              <td align="left">-</td>
            </tr>
            <tr>
              <td align="right">iOS</td>
              <td align="left">-</td>
              <td align="left">-</td>
              <td align="left">
                <i className="ciq-icon ciq-check" />
              </td>
              <td align="left">-</td>
            </tr>
            <tr>
              <td align="right">Linux</td>
              <td align="left">
                <i className="ciq-icon ciq-check" />
              </td>
              <td align="left">
                <i className="ciq-icon ciq-check" />
              </td>
              <td align="left">-</td>
              <td align="left">-</td>
            </tr>
            <tr>
              <td align="right">macOS</td>
              <td align="left">
                <i className="ciq-icon ciq-check" />
              </td>
              <td align="left">
                <i className="ciq-icon ciq-check" />
              </td>
              <td align="left">
                <i className="ciq-icon ciq-check" />
              </td>
              <td align="left">-</td>
            </tr>
            <tr>
              <td align="right">Windows</td>
              <td align="left">
                <i className="ciq-icon ciq-check" />
              </td>
              <td align="left">
                <i className="ciq-icon ciq-check" />
              </td>
              <td align="left">-</td>
              <td align="left">
                <i className="ciq-icon ciq-check" />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="asterix-text">
          <p>
            <span>Note: </span>Make sure your browser or browser version is
            WebRTC supported
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          size="sm"
          color="primary"
          className="footer-btn btn-bold"
          onClick={toggledModal}
        >
          {t("dismiss")}
        </Button>
      </ModalFooter>
    </Modal>,
    modalPortal!
  );
};

export default CompatibilityModal;
