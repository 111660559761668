import { VideoClient } from "@zoom/videosdk";

export function getParticipantsWithoutSelf(client: typeof VideoClient) {
  return client
    .getAllUser()
    .filter(({ userId }) => userId !== client.getCurrentUserInfo().userId)
    .map((participant) => ({
      ...participant,
      displayName: participant.isPhoneUser ? "Dial-in" : participant.displayName
    }));
}
