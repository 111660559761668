import { detect } from "detect-browser";
import React, { Component, ErrorInfo } from "react";
import { isIOS, isSafari } from "react-device-detect";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import Video from "twilio-video";
import { AnalyticsService } from "../../services";
import CompatibilityModal from "../compatibilityModal/CompatibilityModal";

interface ErrorBoundaryProps extends WithTranslation {
  children: React.ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
  isCompatibilityModalOpen: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true, isCompatibilityModalOpen: false };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      isCompatibilityModalOpen: false
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log(JSON.stringify(errorInfo));

    AnalyticsService.appUnhandledException(error, errorInfo);
  }

  toggleCompatibilityModal = (): void => {
    this.setState((prev) => ({
      isCompatibilityModalOpen: !prev.isCompatibilityModalOpen
    }));
  };

  generateBrowserBaseError = (): string => {
    if (isIOS && !isSafari) {
      return "accessFromSafari";
    }

    return "browserNotSupported";
  };

  isSupportedCustom = (): boolean => {
    if (Video.isSupported) {
      return true;
    }
    // check if its a chrome based browser like brave
    const browser = detect();
    if (browser && browser.name === "chrome") return true;

    return false;
  };

  renderBrowserNotSupported = (): JSX.Element => {
    const { isCompatibilityModalOpen } = this.state;
    const { t } = this.props;

    return (
      <div className="error-boundary-container">
        <CompatibilityModal
          isOpen={isCompatibilityModalOpen}
          toggledModal={this.toggleCompatibilityModal}
        />
        <span className="ciq-icon ciq-exclamation-circle-solid" />
        <div className="error-boundary-message">
          <h1>{t(this.generateBrowserBaseError())}</h1>
          <p>{t("errorBoundary:notSupportMessage")}</p>
        </div>
      </div>
    );
  };

  renderErrorBoundary = (): JSX.Element => {
    const { isCompatibilityModalOpen } = this.state;
    const { t } = this.props;
    return (
      <div className="error-boundary-container">
        <CompatibilityModal
          isOpen={isCompatibilityModalOpen}
          toggledModal={this.toggleCompatibilityModal}
        />
        <div className="error-boundary-message">
          <h1>{t("somethingWentWrong")}</h1>
          <p>{t("crashedMessage")}</p>
          <div className="error-cases">
            <ul>
              <li>
                {t("useTheWebsite")}{" "}
                <span onClick={this.toggleCompatibilityModal}>
                  {t("supportedBrowsers")}
                </span>
              </li>
              <li>{t("connectToInternet")}</li>
              <li>{t("updateBrowser")}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  render(): JSX.Element {
    const { hasError } = this.state;
    const { children } = this.props;

    if (!this.isSupportedCustom()) {
      return this.renderBrowserNotSupported();
    }
    if (hasError) {
      return this.renderErrorBoundary();
    }

    return <>{children}</>;
  }
}

export default withTranslation("errorBoundary")(ErrorBoundary);
