/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useRef, useState } from "react";
import { MediaStreamTrackPublishOptions, Room } from "twilio-video";
import { ROOM_STATE_TYPE } from "../../../constants/events";
import { SCREEN_SHARE_TRACK_NAME } from "../../../constants/meeting";
import { AnalyticsService } from "../../../services";

export interface UseShareScreen {
  isScreenSharing: boolean;
  toggleScreenShare: () => void;
}

const useShareScreen = (room: Room): UseShareScreen => {
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const shareScreenStopRef = useRef<VoidFunction | null>(null);

  useEffect(() => {
    room.once(ROOM_STATE_TYPE.DISCONNECTED, () => {
      if (isScreenSharing) setIsScreenSharing(false);
    });
  }, [room, isScreenSharing]);

  const startScreenShare = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: false
      })
      .then((stream: MediaStream) => {
        const track = stream.getTracks()[0];

        room.localParticipant
          .publishTrack(track, {
            name: SCREEN_SHARE_TRACK_NAME,
            priority: "high"
          } as MediaStreamTrackPublishOptions)
          .then((trackPublication) => {
            setIsScreenSharing(true);
            shareScreenStopRef.current = (): void => {
              room.localParticipant.unpublishTrack(track);
              room.localParticipant.emit("trackUnpublished", trackPublication);
              track.stop();
              setIsScreenSharing(false);
            };

            track.onended = shareScreenStopRef.current;
          })
          .catch((error) => {
            AnalyticsService.screenPublishError(error);
          });
      })
      .catch((error) => {
        AnalyticsService.devicePermissionErrors("SCREEN", error);
      });
  }, [room.localParticipant]);

  const toggleScreenShare = useCallback(() => {
    if (isScreenSharing) {
      shareScreenStopRef.current?.();
    } else {
      startScreenShare();
    }
  }, [isScreenSharing, startScreenShare]);

  return {
    isScreenSharing,
    toggleScreenShare
  };
};

export default useShareScreen;
