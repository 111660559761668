import { LocalAudioTrack, LocalVideoTrack } from "twilio-video";
import { LocalTracksState } from "./UseLocalTracks";

export const GET_LOCAL_TRACKS = "GET_LOCAL_TRACKS";

export const GET_LOCAL_VIDEO_TRACK = "GET_LOCAL_VIDEO_TRACK";
export const GET_LOCAL_VIDEO_TRACK_SUCCESS = "GET_LOCAL_VIDEO_TRACK_SUCCESS";
export const GET_LOCAL_VIDEO_TRACK_FAILURE = "GET_LOCAL_VIDEO_TRACK_FAILURE";
export const REMOVE_LOCAL_VIDEO_TRACK = "REMOVE_LOCAL_VIDEO_TRACK";

export const GET_LOCAL_AUDIO_TRACK = "GET_LOCAL_AUDIO_TRACK";
export const GET_LOCAL_AUDIO_TRACK_SUCCESS = "GET_LOCAL_AUDIO_TRACK_SUCCESS";
export const GET_LOCAL_AUDIO_TRACK_FAILURE = "GET_LOCAL_AUDIO_TRACK_FAILURE";

interface GetLocalTracksActionType {
  type: typeof GET_LOCAL_TRACKS;
}

interface GetLocalVideoTrackActionType {
  type: typeof GET_LOCAL_VIDEO_TRACK;
}

interface GetLocalVideoTrackSuccessActionType {
  type: typeof GET_LOCAL_VIDEO_TRACK_SUCCESS;
  payload: {
    localVideo: LocalVideoTrack;
  };
}

interface GetLocalVideoTrackFailureActionType {
  type: typeof GET_LOCAL_VIDEO_TRACK_FAILURE;
}

interface RemoveLocalVideoTrackActionType {
  type: typeof REMOVE_LOCAL_VIDEO_TRACK;
}

interface GetLocalAudioTrackActionType {
  type: typeof GET_LOCAL_AUDIO_TRACK;
}

interface GetLocalAudioTrackSuccessActionType {
  type: typeof GET_LOCAL_AUDIO_TRACK_SUCCESS;
  payload: {
    localAudio: LocalAudioTrack;
  };
}

interface GetLocalAudioTrackFailureActionType {
  type: typeof GET_LOCAL_AUDIO_TRACK_FAILURE;
}

type LocalTrackReducerAction =
  | GetLocalTracksActionType
  | GetLocalVideoTrackActionType
  | GetLocalVideoTrackSuccessActionType
  | GetLocalVideoTrackFailureActionType
  | GetLocalAudioTrackActionType
  | GetLocalAudioTrackSuccessActionType
  | GetLocalAudioTrackFailureActionType
  | RemoveLocalVideoTrackActionType;

export function localTrackReducer(
  state: LocalTracksState,
  action: LocalTrackReducerAction
): LocalTracksState {
  switch (action.type) {
    case GET_LOCAL_TRACKS:
      return { ...state, isCamRequesting: true, isMicRequesting: true };
    case GET_LOCAL_VIDEO_TRACK:
      return {
        ...state,
        isCamRequesting: true,
        localVideoTrack: undefined
      };
    case GET_LOCAL_VIDEO_TRACK_SUCCESS:
      return {
        ...state,
        isCamRequesting: false,
        localVideoTrack: action.payload.localVideo
      };
    case GET_LOCAL_VIDEO_TRACK_FAILURE:
      return {
        ...state,
        isCamRequesting: false
      };
    case REMOVE_LOCAL_VIDEO_TRACK:
      return {
        ...state,
        localVideoTrack: undefined
      };
    case GET_LOCAL_AUDIO_TRACK:
      return {
        ...state,
        isMicRequesting: true,
        localAudioTrack: undefined
      };
    case GET_LOCAL_AUDIO_TRACK_SUCCESS:
      return {
        ...state,
        isMicRequesting: false,
        localAudioTrack: action.payload.localAudio
      };
    case GET_LOCAL_AUDIO_TRACK_FAILURE:
      return {
        ...state,
        isMicRequesting: false
      };
    default:
      return state;
  }
}
