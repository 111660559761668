import {
  PEER_SCREEN_SHARE_CANVAS,
  SELF_SCREEN_SHARE_CANVAS,
  SELF_SCREEN_SHARE_VIDEO
} from "../../components/zoomRoom/ScreenShare";
import { ZOOM_MEETING_VIDEOS_ID } from "../../constants/ui";

const WRAPPER_CSS_CLASS = "wrapper-for-screen-sharing";

export class ScreenShareUI {
  private videosWrapper: HTMLDivElement | undefined;
  private videoPlayerContainer: Element | undefined;
  shareVideoElement: HTMLVideoElement | undefined;
  shareCanvasElement: HTMLCanvasElement | undefined;
  peerShareCanvasElement: HTMLCanvasElement | undefined;

  // It has to be called when joining the session (not before - too early)
  loadDocument() {
    this.videosWrapper = document.getElementById(
      ZOOM_MEETING_VIDEOS_ID
    ) as HTMLDivElement;
    this.videoPlayerContainer = document.querySelector(
      "video-player-container"
    ) as Element;
    this.shareVideoElement = document.getElementById(
      SELF_SCREEN_SHARE_VIDEO
    ) as HTMLVideoElement;
    this.shareCanvasElement = document.getElementById(
      SELF_SCREEN_SHARE_CANVAS
    ) as HTMLCanvasElement;
    this.peerShareCanvasElement = document.getElementById(
      PEER_SCREEN_SHARE_CANVAS
    ) as HTMLCanvasElement;
  }

  setUpSharingView(screenShareElement?: HTMLElement) {
    if (this.videoPlayerContainer) {
      this.videoPlayerContainer.className = "meeting-with-screen-sharing";
    }

    if (this.videosWrapper) {
      this.videosWrapper.classList.add(WRAPPER_CSS_CLASS);
    }

    const targetElement = screenShareElement ?? this.peerShareCanvasElement;
    targetElement!.style.display = "block";
  }

  tearDownSharingView() {
    if (this.shareVideoElement !== undefined) {
      this.shareVideoElement.style.display = "none";
    }

    if (this.shareCanvasElement !== undefined) {
      this.shareCanvasElement.style.display = "none";
    }

    if (this.videosWrapper) {
      this.videosWrapper.classList.remove(WRAPPER_CSS_CLASS);
    }
  }
}
