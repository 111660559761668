import clsx from "clsx";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { TabContent, TabPane } from "reactstrap";
import SimpleBar from "simplebar-react";

import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_PARTICIPANT_LIST
} from "../../../constants/ui";
import { useMeetingContext } from "../../../hooks";
import useParticipants from "../../../hooks/useParticipants/UseParticipants";
import { UtilService } from "../../../services";
import DialInTab from "../dialInTab/DialInTab";
import NotJoinedParticipantItem from "./NotJoinedParticipantItem";
import ParticipantItem from "./ParticipantItem";

import useNotJoinedParticipants from "../../../hooks/useNotJoinedParticipant/useNotJoinedParticipant";

enum PARTICIPANTS_TABS {
  LISTING,
  DIAL_IN
}

const ParticipantSideBar: React.FC = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<PARTICIPANTS_TABS>(
    PARTICIPANTS_TABS.LISTING
  );
  const { t } = useTranslation("twilioRoom");
  const isLargeDevice = useMediaQuery({
    query: APP_DEVICE_MEDIA_QUERIES.large
  });
  const {
    isParticipantSidebarOpen,
    toggleParticipantSidebar,
    room,
    meeting,
    isMeetingGuest
  } = useMeetingContext();
  const twilioParticipants = useParticipants();

  const { notJoined, addNew, recallExisting, remove } =
    useNotJoinedParticipants();

  const {
    dialInNumbers,
    meetingRoomId,
    meetingUrl,
    isCurrentParticipantAnExpert
  } = meeting!;

  const isNotJoinedExist = notJoined.length > 0;
  const isDialInHide = isMeetingGuest || isCurrentParticipantAnExpert;
  const showNotJoinedSection = isNotJoinedExist && !isDialInHide;

  const handleCopyUrl = (): void => {
    UtilService.copyToClipboard(
      meetingUrl.concat("\n").concat(
        ...Object.values(dialInNumbers || {}).map((v, i, a) =>
          t("room:dialInPhonePlain", {
            phone: v,
            pin: UtilService.formatMeetingCodeId(meetingRoomId)
          }).concat(i === a.length - 1 ? "" : "\n")
        )
      )
    );
  };

  const handleEnterNumber = (): void => {
    setActiveTab(PARTICIPANTS_TABS.DIAL_IN);
  };

  const handleNavigateBack = (): void => {
    setActiveTab(PARTICIPANTS_TABS.LISTING);
  };

  return (
    <div
      className={clsx("sidebar", {
        "is-open": isParticipantSidebarOpen
      })}
    >
      <TabContent activeTab={activeTab}>
        <TabPane tabId={PARTICIPANTS_TABS.LISTING}>
          <div className="participant-sidebar-body">
            <div className="sidebar-header">
              <h2>{t("participants")}</h2>
              <div className="btn-close" onClick={toggleParticipantSidebar}>
                <i className="ciq-icon ciq-close" />
              </div>
            </div>
            <hr />
            <SimpleBar
              style={{
                maxHeight:
                  UI_PARTICIPANT_LIST.PARTICIPANT_LIST_CONTAINER_HEIGHT(
                    !isLargeDevice
                  ),
                height: UI_PARTICIPANT_LIST.PARTICIPANT_LIST_CONTAINER_HEIGHT(
                  !isLargeDevice
                )
              }}
              className="simplebar-light"
            >
              <SimpleBar
                style={{
                  maxHeight: UI_PARTICIPANT_LIST.PARTICIPANT_LIST_HEIGHT(
                    isLargeDevice,
                    !!isMeetingGuest,
                    Object.values(dialInNumbers || {}).length
                  ),
                  height: UI_PARTICIPANT_LIST.PARTICIPANT_LIST_HEIGHT(
                    isLargeDevice,
                    !!isMeetingGuest,
                    Object.values(dialInNumbers || {}).length
                  )
                }}
                className="participant-list-container simplebar-light"
              >
                <ParticipantItem participant={room.localParticipant} me />
                {twilioParticipants.map((p) => (
                  <ParticipantItem participant={p} key={p.identity} />
                ))}

                {showNotJoinedSection && (
                  <div className="waiting-list-container">
                    <div className="separator-container">
                      <p>{t("notJoinedYet")}</p>
                      <hr />
                    </div>
                    {notJoined.map((notJoinedParticipant) => (
                      <NotJoinedParticipantItem
                        key={notJoinedParticipant.phoneNumber}
                        notJoinedParticipant={notJoinedParticipant}
                        onCallEnd={remove}
                      />
                    ))}
                  </div>
                )}
              </SimpleBar>
              <div className="meeting-info-container">
                <hr />
                <h3>{t("addOthers")}</h3>
                <div className="join-options-container">
                  <div className="meeting-share-info">
                    <p title={meetingUrl}>{meetingUrl}</p>
                    {dialInNumbers &&
                      Object.values(dialInNumbers).map((value) => (
                        <p key={value}>
                          {t("dialInPhonePlain", {
                            phone: value,
                            pin: UtilService.formatMeetingCodeId(meetingRoomId)
                          })}
                        </p>
                      ))}
                  </div>
                  <div className="option-item">
                    <span
                      className="ciq-icon ciq-icon-ic-copy pointer-on"
                      onClick={handleCopyUrl}
                    />
                    <div className="option-text">{t("copyInfo")}</div>
                  </div>
                  {!isDialInHide && (
                    <div className="option-item">
                      <span className="ciq-icon ciq-icon-ic-on-phone-dial-in" />
                      <div className="option-text">{t("dialSomeone")}</div>
                      <span className="option-link" onClick={handleEnterNumber}>
                        {t("enterNumber")}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </SimpleBar>
          </div>
        </TabPane>
        <TabPane tabId={PARTICIPANTS_TABS.DIAL_IN}>
          <DialInTab
            dialInNumbers={dialInNumbers}
            onNavigateBack={handleNavigateBack}
            notJoinedParticipants={notJoined}
            onNewDialIn={addNew}
            onRedial={recallExisting}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default ParticipantSideBar;
