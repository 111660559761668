import { Track, VideoBandwidthProfileOptions, VideoTrack } from "twilio-video";
import {
  GUEST_APPROVAL_STATE,
  MEETING_STATUS,
  MEETING_TYPE,
  RECORDING_STATUS
} from "../constants/meeting";
import { DialInStatus } from "./view/participants";

interface Attachment {
  fileName: string;
  downloadUrl: string;
  fileSize: number;
}

export interface Meeting {
  approval: GUEST_APPROVAL_STATE;

  meetingType: MEETING_TYPE;
  title: string;
  timezone: string;
  startTime: Date;
  endTime: Date;
  record: boolean;
  transcribe: boolean;
  meetingRoomId: number;
  twilioToken: string;
  participants: CIQParticipant[];
  attachments: Attachment[];
  meetingStatus: MEETING_STATUS;
  recordingStatus: RECORDING_STATUS;
  pusherChannel: string;
  meetingUrl: string;
  dialInNumbers?: object;
  location?: string;
  joinRequestId?: string;
  notes?: string;
  isCurrentParticipantAnExpert: boolean;
}

export interface MeetingGetResponse {
  approval: GUEST_APPROVAL_STATE;
  joinRequestId?: string;
  title?: string;
  meetingType?: MEETING_TYPE;
  timezone?: string;
  startTime?: Date;
  endTime?: Date;
  record?: boolean;
  transcribe?: boolean;
  meetingRoomId?: number;
  twilioToken?: string;
  participants?: CIQParticipant[];
  attachments?: Attachment[];
  location?: string;
  meetingStatus?: MEETING_STATUS;
  notes?: string;
  recordingStatus?: RECORDING_STATUS;
  dialInNumbers?: object;
  pusherChannel?: string;
  meetingUrl?: string;
  isCurrentParticipantAnExpert: boolean;
}

export interface MeetingRequest {
  meetingId: string;
  name: string;
  email: string;
  recaptchaToken: string;
  browserAgent?: string;
}

export interface CIQMeetingConnectionSettings {
  maxTracks: number;
  bandwidthProfileMode: VideoBandwidthProfileOptions["mode"];
  dominantSpeakerPriority?: Track.Priority;
  renderDimensionLow?: VideoTrack.Dimensions;
  renderDimensionStandard?: VideoTrack.Dimensions;
  renderDimensionHigh?: VideoTrack.Dimensions;
}

export interface CIQParticipant {
  email: string;
  name?: string;
  profileImageUrl?: string;
  phoneNumber?: string;
  hidden: boolean;
  isExpert: boolean;
  expertFirstName?: string;
  organizer: boolean;
}

export interface Timezone {
  id: string;
  displayText: string;
  offset: number;
}

export interface TimezoneResponse {
  items: Timezone[];
}

export interface PusherAuthenticateRequest {
  socketId: string;
  meetingId: string;
  email: string;
  twilioToken: string;
}

export interface PusherAuthenticateResponse {
  auth: string;
  channelData: string;
}

export interface ConnectCrashAnalytics {
  errorMessage: string;
  errorType: string;
  browserName: string;
  fullBrowserVersion: string;
  deviceType: string;
  engineName: string;
  engineVersion: string;
  osName: string;
  osVersion: string;
  mobileModel: string;
  mobileVendor: string;
  meetingId?: string;
  errorCode?: string;
}

export enum ERROR_STATES {
  DISCONNECT_ERROR_STATE = "DISCONNECT_STATE",
  APP_CRASH_ERROR = "APP_CRASH_ERROR"
}

export interface BaseJoinRequest {
  id: string;
  meetingId: string;
  email: string;
  approval: GUEST_APPROVAL_STATE;
}

export interface JoinRequest extends BaseJoinRequest {
  userInfo: GuestUserInfo;
  isTimedOut?: boolean;
}

export interface GuestUserInfo {
  email: string;
  name: string;
  profileImageUrl?: string;
}

export interface ExternalJoinStateUpdateRequest {
  meetingId: string;
  joinRequestId: string;
  twilioToken: string;
  approval: GUEST_APPROVAL_STATE;
}

export interface DialInApiResponse {
  sid: string;
  toFormatted: string;
}

export interface DialInApiRequest {
  meetingId: string;
  callToNumber: string;
  twilioToken: string;
}

export interface UpdateDialInStatusRequest {
  meetingId: string;
  callSid: string;
  twilioToken: string;
}

export interface UpdateDialInStatusResponse {
  callStatus: DialInStatus;
}

export interface RecordMeetingRequest {
  isRecording: boolean;
  pathRoomSid: string;
  participantEmail: string;
}

export interface RecordMeetingSuccess {
  isRecording: boolean;
}
