import { ConfigProvider } from "antd";
import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import "material-symbols";

import "./i18n";
import "./custom";
import { Loader } from "./app/components/Loader/Loader";

import antTheme from "../../../light.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "flag-icon-css/css/flag-icon.min.css";
import "./App.scss";
import "./app/assets/scss/styles.scss";
import "./app/assets/fonts/style.css";
import "simplebar/dist/simplebar.min.css";
import "intl-tel-input/build/css/intlTelInput.css";
import "./index.css";

const App = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ConfigProvider theme={antTheme}>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </ConfigProvider>
);

localStorage.setItem(
  "APP_VERSION",
  // COMMIT_HASH provided by webpack.config.js
  // @ts-ignore
  `${process.env.NX_PUBLIC_ENV}-${COMMIT_HASH}`
);
