import type { CSSProperties } from "react";

import { micAntd, micOffAntd } from "@arbolus-technologies/theme";
import { initialsFromFullName } from "@arbolus-technologies/utils";

// Regular style files don't penetrate the shadow DOM
const INITIALS_STYLE = {
  width: "40px",
  height: "40px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontSize: "var(--size-large)",
  borderRadius: "50%",
  color: "var(--primary-white)",
  background: "rgba(0, 0, 0, 0.25)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  visibility: "hidden"
} as CSSProperties;

const NAME_STYLE = {
  background: "var(--primary-black)",
  color: "var(--primary-white)",
  padding: "8px 10px 8px 20px",
  fontSize: "12px",
  position: "absolute",
  bottom: "8px",
  left: "8px",
  borderRadius: "8px"
} as CSSProperties;

const WRAPPER_ICON_STYLE = {
  position: "absolute",
  bottom: "12px",
  left: "12px",
  zIndex: "1"
} as CSSProperties;

interface ZoomParticipantNameProps {
  displayName: string;
  userId: number;
  muted: boolean;
}

export function ZoomParticipantName({
  displayName,
  userId,
  muted
}: ZoomParticipantNameProps) {
  const micOnIconDisplay = muted ? "none" : "block";
  const micOffIconDisplay = muted ? "block" : "none";

  return (
    <>
      <div
        id={getMicOnIconId(userId)}
        style={{ display: micOnIconDisplay, ...WRAPPER_ICON_STYLE }}
      >
        <img src={micAntd} alt="mic" />
      </div>
      <div
        id={getMicOffIconId(userId)}
        style={{ display: micOffIconDisplay, ...WRAPPER_ICON_STYLE }}
      >
        <img src={micOffAntd} alt="mic-off" />
      </div>
      <span className="initials" style={INITIALS_STYLE}>
        {initialsFromFullName(displayName)}
      </span>
      <span style={NAME_STYLE}>{displayName}</span>
    </>
  );
}

export function getMicOnIconId(userId: number | string) {
  return `mic-on-icon-${userId}`;
}

export function getMicOffIconId(userId: number | string) {
  return `mic-off-icon-${userId}`;
}
