import { ZOOM_MEETING_API } from "../../constants/api";
import { SimpleRestService } from "./../SimpleRestService";

export function checkIfMeetingUsesZoom(meetingId: string) {
  return SimpleRestService.get<{ useZoomConnection: boolean }>(
    ZOOM_MEETING_API.CHECK_IF_USES_ZOOM(meetingId)
  );
}

export function getMeetingConfiguration(
  meetingId: string,
  userName: string,
  sessionPasscode?: string
) {
  const queryParams: any = { userName };
  if (sessionPasscode) queryParams["sessionPasscode"] = sessionPasscode;

  return SimpleRestService.get<{
    videoSDKJWT: string;
    sessionName: string;
    userName: string;
    sessionPasscode?: string;
    meetingTitle: string;
  }>(ZOOM_MEETING_API.GET_CONFIGURATION(meetingId), queryParams);
}
