import React from "react";

import "./ScreenShare.scss";

// Careful! Those names are used in "ScreenShare.scss"
export const SELF_SCREEN_SHARE_VIDEO = "self-screen-share-video";
export const SELF_SCREEN_SHARE_CANVAS = "self-screen-share-canvas";
export const PEER_SCREEN_SHARE_CANVAS = "peer-screen-share-canvas";

const DEFAULT_WIDTH = 640;
const DEFAULT_HEIGHT = 360;

export const ScreenShare = React.memo(() => {
  return (
    <div>
      <video
        id={SELF_SCREEN_SHARE_VIDEO}
        width={DEFAULT_WIDTH}
        height={DEFAULT_HEIGHT}
      />
      <canvas
        id={SELF_SCREEN_SHARE_CANVAS}
        width={DEFAULT_WIDTH}
        height={DEFAULT_HEIGHT}
      />
      <canvas
        id={PEER_SCREEN_SHARE_CANVAS}
        width={DEFAULT_WIDTH}
        height={DEFAULT_HEIGHT}
      />
    </div>
  );
});
