import ZoomVideo, {
  ConnectionChangePayload,
  ConnectionState,
  Participant,
  Stream,
  VideoClient
} from "@zoom/videosdk";
import { ZoomEvents } from "../../constants/zoomEvents";

import { debug } from "../../utils/logging";
import {
  ADDED_PARTICIPANTS_EVENT,
  MeetingParticipants,
  REMOVED_PARTICIPANT_EVENT,
  UPDATED_PARTICIPANTS_EVENT
} from "./MeetingParticipants";
import { MeetingVideo } from "./MeetingVideo";

export class ZoomMeeting {
  private client: typeof VideoClient;
  private stream: typeof Stream | undefined;

  private participants: MeetingParticipants;
  private video: MeetingVideo;

  private microphoneId: string | undefined;
  private speakerId: string | undefined;

  private eventStopper = new AbortController();

  get isSelfSharingScreen() {
    return this.video.isSelfSharingScreen;
  }
  get isPeerSharingScreen() {
    return this.video.isPeerSharingScreen;
  }
  addStartShareListener: (listener: (e: Event) => void) => void;
  removeStartShareListener: (listener: (e: Event) => void) => void;
  addStopShareListener: (listener: (e: Event) => void) => void;
  removeStopShareListener: (listener: (e: Event) => void) => void;

  constructor() {
    this.client = ZoomVideo.createClient();
    this.client.on(ZoomEvents.ConnectionChange, (payload) => {
      debug("Connection change", payload);
      ZoomMeeting.onConnectionChange(payload);
    });
    this.client.on(ZoomEvents.DialoutStateChange, (payload) => {
      debug("Dial-out state change", payload);
    });

    this.participants = new MeetingParticipants(this.client);
    this.video = new MeetingVideo(this.client);

    this.addStartShareListener = this.video.addStartShareListener;
    this.removeStartShareListener = this.video.removeStartShareListener;
    this.addStopShareListener = this.video.addStopShareListener;
    this.removeStopShareListener = this.video.removeStopShareListener;
  }

  static onConnectionChange(payload: ConnectionChangePayload) {
    if (payload.state === ConnectionState.Closed) {
      debug("Session ended", { reason: payload.reason });
      window.location.replace("/");
    } else if (payload.state === ConnectionState.Reconnecting) {
      debug("The client side has lost connection with the server", {
        reason: payload.reason
      });
    } else if (payload.state === ConnectionState.Connected) {
      debug("Connected to the session");
    } else if (payload.state === ConnectionState.Fail) {
      debug("Failed to reconnect OR user flushed from session", {
        reason: payload.reason
      });
    }
  }

  registerParticipantsCallback(
    callback: (participants: Participant[]) => void
  ) {
    this.participants.addEventListener(
      UPDATED_PARTICIPANTS_EVENT,
      (event) => callback((event as CustomEvent).detail.participants),
      { signal: this.eventStopper.signal }
    );
  }

  /**
   * The session begins when the first user joins.
   * The session name must match the tpc in the Video SDK JWT.
   * The host of the session is the user with a role set to 1 in the Video SDK JWT.
   * The sessionPasscode is optional, but if set for a session, it's required for other users joining that session.
   */
  async join(
    token: string,
    joinerName: string,
    sessionName: string,
    sessionPasscode?: string
  ) {
    await this.client.init("en-US", "Global", {
      patchJsMedia: true,
      enforceMultipleVideos: true,
      leaveOnPageUnload: true
    });
    await this.client.join(sessionName, token, joinerName, sessionPasscode);

    const stream = this.client.getMediaStream();
    this.stream = stream;
    if (this.stream === undefined) throw new Error("No stream!");

    await this.startAudio();
    await this.video.join(stream);

    // Participants can be set up only after audio and video are instantiated
    // That ensures the updates are applied to existing DOM elements (no failures)
    this.participants.join();
    this.participants.addEventListener(
      REMOVED_PARTICIPANT_EVENT,
      (event) => {
        this.video.removeParticipantVideo((event as CustomEvent).detail.userId);
      },
      { signal: this.eventStopper.signal }
    );
    this.participants.addEventListener(
      ADDED_PARTICIPANTS_EVENT,
      (event) => {
        this.video.addPlaceholderVideoTiles(
          (event as CustomEvent).detail.addedParticipants
        );
      },
      { signal: this.eventStopper.signal }
    );
  }

  async leave() {
    this.participants.leave();
    this.eventStopper.abort();
    await this.video.leave();
    await this.stream?.stopAudio();
    await this.client.leave();
    delete this.stream;
  }

  async removeUser(userId: number) {
    await this.participants.removeUser(userId);
  }

  leaveWithoutCleanup() {
    this.client.leave();
  }

  getCurrentUserInfo() {
    return this.client.getCurrentUserInfo();
  }

  // Video methods

  async startVideoPreview(deviceId: string, target: "lobby" | "modal") {
    await this.video.startVideoPreview(deviceId, target);
  }

  async stopVideoPreview() {
    await this.video.stopVideoPreview();
  }

  async startSelfVideo() {
    await this.video.startSelfVideo();
  }

  async stopSelfVideo() {
    await this.video.stopSelfVideo();
  }

  showPaginatedVideos(page: number) {
    return this.video.showPaginatedVideos(page);
  }

  getCameraList(): Promise<MediaDeviceInfo[]> {
    return this.video.getCameraList();
  }

  getActiveCamera(): string {
    return this.stream?.getActiveCamera()!;
  }

  getActiveMicrophone(): string {
    return this.stream?.getActiveMicrophone()!;
  }

  async switchCameraInput(deviceId: string) {
    debug("Switching camera input to", deviceId);
    await this.video.switchCameraInput(deviceId);
  }

  getCameraId() {
    // Normally you would use a getter e.g. "get cameraId() { ... }" but we need to expose this to React
    // Because it's a function, we can get the current value any time - a regular variable would be stale
    // That's because React can't listen to updates of a value like this easily
    return this.video.cameraId;
  }

  async shareMyScreen() {
    await this.video.shareMyScreen();
  }

  async stopSharingScreen() {
    await this.video.stopSharingScreen();
  }

  // Audio methods

  private async startAudio() {
    await this.stream?.startAudio({
      microphoneId: this.microphoneId,
      speakerId: this.speakerId,
      autoStartAudioInSafari: true
    });
  }

  async mute() {
    await this.stream?.muteAudio();
  }

  async unmute() {
    await this.stream?.unmuteAudio();
  }

  getMicrophoneId() {
    return this.microphoneId;
  }

  getSpeakerId() {
    return this.speakerId;
  }

  async getMicList() {
    const devices = await ZoomVideo.getDevices();
    const audioInputs = devices.filter(
      (device) => device.kind === "audioinput"
    );
    return audioInputs;
  }

  async switchMicrophone(microphoneId: string) {
    this.microphoneId = microphoneId;
    await this.stream?.switchMicrophone(microphoneId);
  }

  async getSpeakerList() {
    const devices = await ZoomVideo.getDevices();
    const audioOutputs = devices.filter(
      (device) => device.kind === "audiooutput"
    );
    return audioOutputs;
  }

  async switchSpeaker(speakerId: string) {
    this.speakerId = speakerId;
    await this.stream?.switchSpeaker(speakerId);
  }

  async setSpeakerVolume(volume: number) {
    const userIds = this.participants.participants.map(
      (participant) => participant.userId
    );
    await Promise.all(
      userIds.map((userId) =>
        this.stream?.adjustUserAudioVolumeLocally(userId, volume)
      )
    );
  }

  // Phone methods

  supportsPhoneFeature() {
    return this.stream?.isSupportPhoneFeature();
  }

  getCurrentSessionCallinInfo() {
    return this.stream?.getCurrentSessionCallinInfo();
  }

  async makeCall(countryCode: string, phoneNumber: string, name: string) {
    await this.stream?.inviteByPhone(countryCode, phoneNumber, name);
  }
}
