import { useCallback, useEffect, useReducer } from "react";
import Twillio, { LocalAudioTrack, LocalVideoTrack } from "twilio-video";
import { useModalContext } from "../..";
import { DEVICE_ENUMERATION_STACKS } from "../../../constants/errors";
import { TRACK_KIND } from "../../../constants/media";
import { AnalyticsService, UtilService } from "../../../services";

import { PermissionModalEvents } from "../../modalContext/usePermissionModal/UsePermissionModal";
import {
  GET_LOCAL_AUDIO_TRACK,
  GET_LOCAL_AUDIO_TRACK_FAILURE,
  GET_LOCAL_AUDIO_TRACK_SUCCESS,
  GET_LOCAL_TRACKS,
  GET_LOCAL_VIDEO_TRACK,
  GET_LOCAL_VIDEO_TRACK_FAILURE,
  GET_LOCAL_VIDEO_TRACK_SUCCESS,
  REMOVE_LOCAL_VIDEO_TRACK,
  localTrackReducer
} from "./LocalTracksReducer";

export interface UseLocalTracks {
  localAudioTrack?: LocalAudioTrack;
  localVideoTrack?: LocalVideoTrack;
  isMicRequesting: boolean;
  isCamRequesting: boolean;
  disableVideoTrack: () => void;
  disableAudioTrack: () => void;
  enableAudioTrack: () => void;
  getLocalVideoTrack: () => Promise<Twillio.LocalVideoTrack>;
  getLocalAudioTrack: () => Promise<Twillio.LocalAudioTrack>;
}

export interface LocalTracksState {
  localAudioTrack?: LocalAudioTrack;
  localVideoTrack?: LocalVideoTrack;
  isMicRequesting: boolean;
  isCamRequesting: boolean;
}

const initialState = {
  isCamRequesting: false,
  isMicRequesting: false
} as LocalTracksState;

export default function useLocalTracks(): UseLocalTracks {
  const [localTracksState, dispatch] = useReducer(
    localTrackReducer,
    initialState
  );
  const { localAudioTrack, localVideoTrack, isCamRequesting, isMicRequesting } =
    localTracksState;
  const {
    togglePermissionModal,
    selectedAudioDeviceId,
    selectedVideoDeviceId
  } = useModalContext();

  useEffect(() => {
    dispatch({ type: GET_LOCAL_TRACKS });

    Twillio.createLocalTracks({ audio: true })
      .then((tracks) => {
        const audioTrack = tracks.find(
          (track) => track.kind === TRACK_KIND.AUDIO
        );

        if (audioTrack) {
          AnalyticsService.initialMicrophoneLoad(audioTrack as LocalAudioTrack);
          dispatch({
            type: GET_LOCAL_AUDIO_TRACK_SUCCESS,
            payload: {
              localAudio: audioTrack as LocalAudioTrack
            }
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_LOCAL_AUDIO_TRACK_FAILURE
        });
        togglePermissionModal(true, PermissionModalEvents.MIC_BLOCKED);
        AnalyticsService.devicePermissionErrors(TRACK_KIND.AUDIO, error);
      });

    // Request local video tracks if available
    UtilService.hasLocalVideoInputDevices()
      .then((hasVideoInputDevices) => {
        if (hasVideoInputDevices) {
          Twillio.createLocalTracks({ video: true })
            .then((tracks) => {
              const videoTrack = tracks.find(
                (track) => track.kind === TRACK_KIND.VIDEO
              );

              if (videoTrack) {
                AnalyticsService.initialCameraLoad(
                  videoTrack as LocalVideoTrack
                );
                dispatch({
                  type: GET_LOCAL_VIDEO_TRACK_SUCCESS,
                  payload: {
                    localVideo: videoTrack as LocalVideoTrack
                  }
                });
              }
            })
            .catch((error) => {
              dispatch({
                type: GET_LOCAL_VIDEO_TRACK_FAILURE
              });

              AnalyticsService.devicePermissionErrors(TRACK_KIND.VIDEO, error);

              togglePermissionModal(true, PermissionModalEvents.CAM_BLOCKED);
            });
        } else {
          dispatch({
            type: GET_LOCAL_VIDEO_TRACK_FAILURE
          });
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch((error) => {
        AnalyticsService.deviceEnumerationError(
          error,
          DEVICE_ENUMERATION_STACKS.USE_LOCAL_TRACKS
        );
      });
  }, [togglePermissionModal]);

  // Camera Handlers
  const disableVideoTrack = useCallback(() => {
    if (localVideoTrack) {
      localVideoTrack.stop();
      dispatch({
        type: REMOVE_LOCAL_VIDEO_TRACK
      });
    }
  }, [localVideoTrack]);

  const getLocalVideoTrack = useCallback(() => {
    dispatch({
      type: GET_LOCAL_VIDEO_TRACK
    });

    return Twillio.createLocalVideoTrack({ deviceId: selectedVideoDeviceId })
      .then((track) => {
        dispatch({
          type: GET_LOCAL_VIDEO_TRACK_SUCCESS,
          payload: {
            localVideo: track
          }
        });
        return track;
      })
      .catch((err) => {
        dispatch({
          type: GET_LOCAL_VIDEO_TRACK_FAILURE
        });
        togglePermissionModal(true, PermissionModalEvents.CAM_BLOCKED);
        return err;
      });
  }, [togglePermissionModal, selectedVideoDeviceId]);

  // Microphone Handlers
  const disableAudioTrack = useCallback(() => {
    if (localAudioTrack) {
      localAudioTrack.disable();
    }
  }, [localAudioTrack]);

  const enableAudioTrack = useCallback(() => {
    if (localAudioTrack) {
      localAudioTrack.enable();
    }
  }, [localAudioTrack]);

  const getLocalAudioTrack = useCallback(() => {
    dispatch({
      type: GET_LOCAL_AUDIO_TRACK
    });

    return Twillio.createLocalAudioTrack({ deviceId: selectedAudioDeviceId })
      .then((track) => {
        dispatch({
          type: GET_LOCAL_AUDIO_TRACK_SUCCESS,
          payload: {
            localAudio: track
          }
        });
        return track;
      })
      .catch((err) => {
        dispatch({
          type: GET_LOCAL_AUDIO_TRACK_FAILURE
        });
        togglePermissionModal(true, PermissionModalEvents.MIC_BLOCKED);
        return err;
      });
  }, [togglePermissionModal, selectedAudioDeviceId]);

  return {
    localAudioTrack,
    localVideoTrack,
    disableVideoTrack,
    enableAudioTrack,
    disableAudioTrack,
    getLocalVideoTrack,
    isCamRequesting,
    isMicRequesting,
    getLocalAudioTrack
  };
}
