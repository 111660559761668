import { EventEmitter } from "events";
import i18next from "i18next";
import moment from "moment";
import { useCallback, useEffect, useReducer, useRef } from "react";
import {
  browserName,
  deviceType,
  engineName,
  engineVersion,
  fullBrowserVersion,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Subscription } from "rxjs";
import Twillio, {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  Room
} from "twilio-video";
import { TWILLIO_ERROR_CODES } from "../../../constants/app";

import { ErrorResponse } from "../../../models/api";
import {
  BaseJoinRequest,
  CIQMeetingConnectionSettings,
  CIQParticipant,
  ConnectCrashAnalytics,
  ERROR_STATES,
  JoinRequest,
  Meeting,
  MeetingGetResponse,
  MeetingRequest
} from "../../../models/meeting";

import {
  GUEST_APPROVAL_STATE,
  MAX_PARTICIPANT_VIEWS
} from "../../../constants/meeting";
import {
  AnalyticsService,
  DiagnosticService,
  LegacyMeetingService,
  NotificationService,
  UtilService
} from "../../../services";
import {
  ADD_NEW_PARTICIPANT,
  ADD_PARTICIPANTS,
  DISCONNECT_MEETING,
  GET_MEETING,
  GET_MEETING_FAILURE,
  GET_MEETING_SUCCESS,
  SET_RECORDING,
  UPDATE_GUEST_APPROVAL_STATE,
  meetingReducer
} from "./MeetingStateReducer";

import {
  AUDIO_PUBLISH_STACKS,
  VIDEO_PUBLISH_STACKS
} from "../../../constants/errors";
import { PARTICIPANT_STATE, ROOM_STATE_TYPE } from "../../../constants/events";
import {
  MAX_POLLING_TIME,
  POLLING_TIME_INTERVAL
} from "../../../constants/timer";
import { TOAST_POSITION } from "../../../services/NotificationService";
import PusherService from "../../../services/PusherService";
import useModalContext from "../../useModalContext/useModalContext";

export interface MeetingState {
  isConnecting: boolean;
  meeting?: Meeting;
  room: Room;
  userEmail?: string;
  twilioToken?: string;
  meetingError?: ErrorResponse;
  approvalType?: GUEST_APPROVAL_STATE;
  isMeetingGuest?: boolean;
  isOrganizer: boolean;
  isRecording: boolean;
  isRecordingCounter: number;
}

export interface UseRoom {
  isConnecting: boolean;
  room: Room;
  meeting?: Meeting;
  userEmail?: string;
  twilioToken?: string;
  meetingError?: ErrorResponse;
  approvalType?: GUEST_APPROVAL_STATE;
  connect: (name: string, email: string, recaptchaToken: string) => void;
  setMeetingLoading: () => void;
  setMeetingError: (error: ErrorResponse | undefined) => void;
  isMeetingGuest?: boolean;
  isOrganizer: boolean;
  isRecording: boolean;
  setRecording: () => void;
  isRecordingCounter: number;
}

const meetingConnectionSettings: CIQMeetingConnectionSettings = {
  bandwidthProfileMode: "collaboration",
  maxTracks: MAX_PARTICIPANT_VIEWS(isMobile) - 1,
  dominantSpeakerPriority: "high",
  renderDimensionLow: { width: 640, height: 480 },
  renderDimensionStandard: { width: 1280, height: 720 },
  renderDimensionHigh: { width: 1920, height: 1080 }
};

const initialState = {
  isConnecting: false,
  room: new EventEmitter() as Room
} as MeetingState;

export default function useRoom(
  addJoinRequest: (joinRequest: JoinRequest) => void,
  removeJoinRequest: (requestId: string) => void,
  localAudioTrack?: LocalAudioTrack,
  localVideoTrack?: LocalVideoTrack
): UseRoom {
  const [meetingState, dispatch] = useReducer(meetingReducer, initialState);
  const localAudioTrackRef = useRef<LocalAudioTrack>();
  const localVideoTrackRef = useRef<LocalVideoTrack>();
  const pollingTimeOutRef = useRef<any>();
  const { t } = useTranslation("twilioRoom");
  const { toggleAutoplayModal } = useModalContext();

  const fetchMeetingSubscription = useRef(new Subscription());
  const userStateSubscription = useRef(new Subscription());
  const fetchExternalParticipantSubscription = useRef(new Subscription());
  const appCrashSubscription = useRef(new Subscription());

  useEffect(() => {
    localAudioTrackRef.current = localAudioTrack;
    localVideoTrackRef.current = localVideoTrack;
  }, [localAudioTrack, localVideoTrack]);

  useEffect(
    () => (): void => {
      fetchMeetingSubscription.current.unsubscribe();
      userStateSubscription.current.unsubscribe();
      fetchExternalParticipantSubscription.current.unsubscribe();
      appCrashSubscription.current.unsubscribe();
      if (pollingTimeOutRef.current) clearInterval(pollingTimeOutRef.current);
    },
    []
  );

  const {
    isConnecting,
    meeting,
    room,
    userEmail,
    twilioToken,
    meetingError,
    approvalType,
    isMeetingGuest,
    isOrganizer,
    isRecording,
    isRecordingCounter
  } = meetingState;

  const setMeetingLoading = useCallback(() => {
    dispatch({ type: GET_MEETING });
  }, []);

  const setRecording = useCallback(() => {
    LegacyMeetingService.recordMeeting({
      isRecording: !isRecording,
      pathRoomSid: room.sid,
      participantEmail: userEmail || ""
    }).subscribe(
      () => {
        dispatch({
          type: SET_RECORDING,
          payload: { isRecording, isRecordingCounter }
        });
      },
      (error: ErrorResponse) => {
        console.log(error);
      }
    );
  }, [isRecording, room.sid, userEmail, isRecordingCounter]);

  const setMeetingError = useCallback((error: ErrorResponse | undefined) => {
    dispatch({
      type: GET_MEETING_FAILURE,
      payload: { meetingError: error }
    });
  }, []);

  const meetingResponseDataToMeeting = (data: MeetingGetResponse): Meeting => {
    const {
      twilioToken: TwilioToken,
      approval,
      attachments,
      endTime,
      location,
      meetingRoomId,
      meetingStatus,
      meetingType,
      notes,
      participants,
      pusherChannel,
      record,
      recordingStatus,
      startTime,
      timezone,
      title,
      transcribe,
      dialInNumbers,
      meetingUrl,
      isCurrentParticipantAnExpert
    } = data;

    return {
      approval,
      attachments: attachments!,
      endTime: endTime!,
      location,
      meetingRoomId: meetingRoomId!,
      meetingStatus: meetingStatus!,
      meetingType: meetingType!,
      notes,
      participants: participants!,
      pusherChannel: pusherChannel!,
      record: record!,
      recordingStatus: recordingStatus!,
      startTime: startTime!,
      timezone: timezone!,
      title: title!,
      dialInNumbers,
      transcribe: transcribe!,
      meetingUrl: meetingUrl!,
      twilioToken: TwilioToken!,
      isCurrentParticipantAnExpert
    };
  };

  const connect = useCallback(
    (name: string, email: string, recaptchaToken: string) => {
      const meetingId = LegacyMeetingService.getMeetingIdFromURL();

      AnalyticsService.identifyUser(email);

      const {
        maxTracks,
        renderDimensionHigh,
        renderDimensionStandard,
        renderDimensionLow,
        bandwidthProfileMode,
        dominantSpeakerPriority
      } = meetingConnectionSettings;
      const { APPROVED, DECLINED, PENDING } = GUEST_APPROVAL_STATE;

      const handleConnectToRoom = (
        token: string,
        meetingDetails: Meeting,
        isGuest = false
      ): void => {
        const { pusherChannel } = meetingDetails;
        Twillio.connect(token, {
          tracks: [],
          dominantSpeaker: true,
          bandwidthProfile: {
            video: {
              mode: bandwidthProfileMode,
              maxTracks,
              dominantSpeakerPriority,
              renderDimensions: {
                high: renderDimensionHigh,
                standard: renderDimensionStandard,
                low: renderDimensionLow
              }
            }
          },
          networkQuality: {
            local: 3
          }
        })
          .then(
            (newRoom) => {
              const { participants } = meetingDetails;

              // Find my profile
              const connectedParticipant = participants.find(
                (participant) => participant.email === email
              );

              AnalyticsService.connectedToMeeting(
                email,
                name,
                meetingId!,
                localAudioTrackRef.current,
                localVideoTrackRef.current
              );

              // Set joined name
              if (connectedParticipant) connectedParticipant.name = name;
              else
                participants.push({
                  email,
                  name
                } as CIQParticipant);

              const participantDataMap =
                UtilService.createParticipantDataMap(participants);

              dispatch({
                type: GET_MEETING_SUCCESS,
                payload: {
                  meeting: meetingDetails,
                  room: newRoom,
                  email,
                  twilioToken: token,
                  isMeetingGuest: isGuest,
                  isOrganizer: connectedParticipant?.organizer || false
                }
              });

              // User state update API call for diagnostics purposes
              const handleUserStateUpdate = (): void => {
                userStateSubscription.current =
                  DiagnosticService.sendUserJoinedState(
                    meetingId!,
                    email,
                    token
                  ).subscribe();
              };

              const handleNewJoinRequest = (guestData: JoinRequest): void => {
                addJoinRequest(guestData);
              };

              const handleParticipantJoined = (
                participant: CIQParticipant
              ): void => {
                const { email: pEmail } = participant;

                participantDataMap.set(pEmail, participant);

                NotificationService.showInfo(
                  t("joinedTheMeeting", {
                    participantDisplayName: getUserName(pEmail)
                  })
                );

                dispatch({
                  type: ADD_NEW_PARTICIPANT,
                  payload: {
                    participant
                  }
                });
              };

              const handleJoinRequestUpdate = ({
                id
              }: BaseJoinRequest): void => {
                removeJoinRequest(id);
              };

              const handleJoinedParticipantsAdd = (
                onlineParticipants: CIQParticipant[]
              ): void => {
                onlineParticipants.forEach((participant) => {
                  participantDataMap.set(participant.email, participant);
                });

                dispatch({
                  type: ADD_PARTICIPANTS,
                  payload: {
                    participants: onlineParticipants
                  }
                });
              };

              // Send user state after any user joined to the room
              if (meetingId) {
                // Authenticate pusher
                PusherService.subscribeToChannel(
                  pusherChannel,
                  email,
                  meetingId,
                  token,
                  isGuest,
                  handleUserStateUpdate,
                  handleNewJoinRequest,
                  handleJoinRequestUpdate,
                  handleParticipantJoined,
                  handleJoinedParticipantsAdd
                );
              }

              if (localAudioTrackRef.current) {
                newRoom.localParticipant
                  .publishTrack(localAudioTrackRef.current)
                  .catch((error) => {
                    AnalyticsService.audioPublishError(
                      error,
                      localAudioTrackRef.current!,
                      AUDIO_PUBLISH_STACKS.USE_ROOM
                    );
                  });
              }

              if (localVideoTrackRef.current) {
                newRoom.localParticipant
                  .publishTrack(localVideoTrackRef.current)
                  .catch((error) => {
                    AnalyticsService.videoPublishError(
                      error,
                      localVideoTrackRef.current!,
                      VIDEO_PUBLISH_STACKS.USE_ROOM
                    );
                  });
              }

              const disconnect = (): void => {
                newRoom.disconnect();
                newRoom.removeAllListeners();

                PusherService.unsubscribeFromChannel();
              };

              const getUserName = (identity: string): string => {
                const ciqParticipantData = participantDataMap.get(identity);
                return UtilService.displayUserName(
                  identity,
                  ciqParticipantData
                );
              };

              const { PARTICIPANT_CONNECTED, PARTICIPANT_DISCONNECTED } =
                PARTICIPANT_STATE;

              // Room Events/Configs
              newRoom.localParticipant.setNetworkQualityConfiguration({
                local: 3
              });
              newRoom.on(PARTICIPANT_CONNECTED, ({ identity }: Participant) => {
                // Show join toast only for Dial In participants
                if (UtilService.isDialInParticipant(identity))
                  NotificationService.showInfo(
                    t("joinedTheMeeting", {
                      participantDisplayName:
                        getUserName(identity) ||
                        UtilService.formatPhoneNumber(identity)
                    })
                  );
              });
              newRoom.on(
                PARTICIPANT_DISCONNECTED,
                ({ identity }: Participant) => {
                  const leftName = getUserName(identity);

                  if (leftName)
                    NotificationService.showInfo(
                      t("leftTheMeeting", {
                        participantDisplayName: leftName
                      })
                    );

                  if (!UtilService.isDialInParticipant(identity)) {
                    participantDataMap.delete(identity);
                  }
                }
              );
              newRoom.on(ROOM_STATE_TYPE.RECONNECTING, () => {
                NotificationService.showInfo(t("reconnecting"));
              });
              newRoom.once(ROOM_STATE_TYPE.DISCONNECTED, (r, error) => {
                // close auto play modal if it's open
                toggleAutoplayModal(false);

                if (error) {
                  const errorData: ConnectCrashAnalytics = {
                    errorMessage: error.message,
                    errorCode: error.code,
                    errorType: ERROR_STATES.DISCONNECT_ERROR_STATE,
                    browserName,
                    deviceType,
                    engineName,
                    engineVersion,
                    fullBrowserVersion,
                    osName,
                    osVersion,
                    meetingId,
                    mobileModel,
                    mobileVendor
                  };

                  appCrashSubscription.current =
                    DiagnosticService.sendAppCrashData(
                      email,
                      errorData
                    ).subscribe();

                  PusherService.unsubscribeFromChannel();

                  if (error.code === TWILLIO_ERROR_CODES.IDENTITY_DUPLICATE) {
                    NotificationService.showError(
                      i18next.t("lobby:sameIdentityErrorMessage")
                    );
                  }

                  AnalyticsService.twillioDisconnectError(
                    error.code,
                    error.message
                  );
                } else {
                  AnalyticsService.twillioDisconnectSuccess();
                }

                setTimeout(() =>
                  dispatch({
                    type: DISCONNECT_MEETING,
                    payload: {
                      room: new EventEmitter() as Room
                    }
                  })
                );

                // Detach Listener to disconnect from the room when user close the browser
                window.removeEventListener("beforeunload", disconnect);
                if (isMobile) {
                  window.removeEventListener("pagehide", disconnect);
                }
              });

              // Attach Listener to disconnect from the room when user close the browser
              window.addEventListener("beforeunload", disconnect);
              if (isMobile) {
                // Add a listener to disconnect from the room when a mobile user closes their browser
                window.addEventListener("pagehide", disconnect);
              }
            },
            (error) => {
              dispatch({
                type: GET_MEETING_FAILURE,
                payload: { meetingError: error }
              });
            }
          )
          .catch((error) => {
            AnalyticsService.twillioConnectError(error);
          });
      };

      const handleJoinRequestResults = (
        nextApprovalType: GUEST_APPROVAL_STATE,
        nextIsConnecting = false
      ): void => {
        dispatch({
          type: UPDATE_GUEST_APPROVAL_STATE,
          payload: {
            approvalType: nextApprovalType,
            isConnecting: nextIsConnecting
          }
        });
      };

      const handlePollingToRoom = (joinRequestId: string): void => {
        handleJoinRequestResults(PENDING, true);

        const requestPollingEndTime = moment().add(MAX_POLLING_TIME, "seconds");
        pollingTimeOutRef.current = setInterval(() => {
          fetchExternalParticipantSubscription.current =
            LegacyMeetingService.getExternalParticipants(
              meetingId!,
              joinRequestId
            ).subscribe(
              (response) => {
                const { approval, twilioToken: TwilioToken } = response;

                if (approval === APPROVED) {
                  handleJoinRequestResults(APPROVED, isConnecting);

                  const meetingDetails = meetingResponseDataToMeeting(response);
                  handleConnectToRoom(TwilioToken!, meetingDetails, true);
                  clearInterval(pollingTimeOutRef.current!);
                } else {
                  handleJoinRequestResults(approval, true);
                  if (approval === DECLINED) {
                    clearInterval(pollingTimeOutRef.current!);
                  }
                }
              },
              (error: ErrorResponse) => {
                clearInterval(pollingTimeOutRef.current!);

                dispatch({
                  type: GET_MEETING_FAILURE,
                  payload: { meetingError: error }
                });
              }
            );

          if (requestPollingEndTime.isBefore(moment())) {
            NotificationService.showError(
              t("timeout"),
              TOAST_POSITION.BOTTOM_CENTER
            );
            dispatch({
              type: UPDATE_GUEST_APPROVAL_STATE,
              payload: {
                approvalType: undefined,
                isConnecting: false
              }
            });
            fetchExternalParticipantSubscription.current.unsubscribe();
            clearInterval(pollingTimeOutRef.current!);
          }
        }, POLLING_TIME_INTERVAL);
      };

      if (recaptchaToken && meetingId) {
        const meetingRequestData: MeetingRequest = {
          name,
          email,
          meetingId,
          recaptchaToken,
          browserAgent: browserName
        };
        fetchMeetingSubscription.current = LegacyMeetingService.getMeeting(
          meetingRequestData
        ).subscribe(
          (response) => {
            const {
              approval,
              twilioToken: TwilioToken,
              joinRequestId
            } = response;

            if (approval === APPROVED) {
              const meetingDetails = meetingResponseDataToMeeting(response);
              handleConnectToRoom(TwilioToken!, meetingDetails);
            } else {
              handlePollingToRoom(joinRequestId!);
            }
          },
          (error: ErrorResponse) => {
            dispatch({
              type: GET_MEETING_FAILURE,
              payload: { meetingError: error }
            });
          }
        );
      }
    },
    [addJoinRequest, isConnecting, removeJoinRequest, t, toggleAutoplayModal]
  );

  return {
    connect,
    isConnecting,
    meeting,
    userEmail,
    twilioToken,
    meetingError,
    room,
    approvalType,
    setMeetingLoading,
    setMeetingError,
    setRecording,
    isMeetingGuest,
    isOrganizer,
    isRecording,
    isRecordingCounter
  };
}
