import { Participant, VideoPlayer } from "@zoom/videosdk";
import React from "react";
import { createRoot } from "react-dom/client";

import {
  ZoomParticipantName,
  getMicOffIconId,
  getMicOnIconId
} from "../../components/zoomParticipantName/ZoomParticipantName";
import { UtilService } from "../UtilService";

export class VideoUI {
  private videoPlayerContainer: HTMLElement | undefined;
  private debouncers: Record<number, Function> = {};

  static toggleInitials(
    parentElement: ShadowRoot | HTMLElement | null,
    visible: boolean
  ) {
    if (!parentElement) throw new Error("Parent element not found!");

    const initials = parentElement.querySelector(`.initials`) as HTMLElement;
    if (initials) {
      initials.style.visibility = visible ? "visible" : "hidden";
    }
  }

  static addNameElement(target: HTMLElement, user: Participant) {
    const { displayName, userId, muted } = user;
    const parent = target.shadowRoot
      ? (target.shadowRoot?.firstChild as HTMLElement)
      : target;
    if (!parent) return;

    parent.style.position = "relative"; // so that "position: absolute" works
    const nameElementRoot = createRoot(parent);
    nameElementRoot.render(
      React.createElement(ZoomParticipantName, {
        displayName,
        userId,
        muted: !!muted // for users not joined to audio the value is "undefined" hence the cast
      })
    );
  }

  static updateMuteIconInsideNameElement(
    parentElement: ShadowRoot | HTMLElement | null,
    userId: number,
    muted: boolean
  ) {
    if (!parentElement) throw new Error("Parent element not found!");

    const micOnIconElement = parentElement.querySelector(
      `#${getMicOnIconId(userId)}`
    ) as HTMLElement | null;
    if (!micOnIconElement) {
      throw new Error("No mic-on icon element!");
    }

    const micOffIconElement = parentElement.querySelector(
      `#${getMicOffIconId(userId)}`
    ) as HTMLElement | null;
    if (!micOffIconElement) throw new Error("No mic-off icon element!");

    if (muted) {
      micOnIconElement.style.display = "none";
      micOffIconElement.style.display = "block";
    } else {
      micOnIconElement.style.display = "block";
      micOffIconElement.style.display = "none";
    }
  }

  static getVideoPreviewElement(target: "lobby" | "modal") {
    return document.getElementById(`zoom-self-video-preview-${target}`) as
      | HTMLVideoElement
      | undefined;
  }

  // It has to be called when joining the session (not before - too early)
  loadDocument() {
    this.videoPlayerContainer = document.querySelector(
      "video-player-container"
    ) as HTMLElement;
    // Override default "display: block" so our own CSS is easier (specificity/inheritance)
    this.videoPlayerContainer.style.display = "";
  }

  addPlaceholderVideoTile(user: Participant, withInitials = true) {
    const placeholderVideo = document.createElement("div");
    placeholderVideo.className = "zoom-video-placeholder";
    this.videoPlayerContainer?.appendChild(placeholderVideo);
    VideoUI.addNameElement(placeholderVideo, user);
    if (withInitials) {
      // Wait until name component is rendered
      setTimeout(() => VideoUI.toggleInitials(placeholderVideo, true), 0);
    }
    return placeholderVideo;
  }

  setVideoGridStyle(participants: number) {
    this.videoPlayerContainer!.className =
      `meeting-with-${Math.min(participants, 8)}-participants`;
  }

  setActiveSpeaker(userVideo: HTMLElement, userId: number) {
    userVideo.classList.add("zoom-active-speaker-video");

    if (this.debouncers[userId] === undefined) {
      this.debouncers[userId] = UtilService.debouncer(() => {
        userVideo.classList.remove("zoom-active-speaker-video");
      });
    }
    this.debouncers[userId]();
  }

  prependUserVideo(userVideo: VideoPlayer, user: Participant) {
    this.videoPlayerContainer!.prepend(userVideo);
    VideoUI.addNameElement(userVideo, user);
  }

  addUserVideo(userVideo: VideoPlayer, user: Participant) {
    this.videoPlayerContainer!.appendChild(userVideo);
    VideoUI.addNameElement(userVideo, user);
  }
}
